import Vue from 'vue';
import { Message, MessageBox } from 'element-ui';
import router from '@/router';
import store from '@/store';
import Cookies from 'js-cookie';
import { profile, myLoginList, adaccouts, dingAuthCode } from '@/api/common/user';
import { synBM } from '@/api/adAssets';
import * as dd from 'dingtalk-jsapi';
import { addMark } from '@/utils/watermark';
import { getToken, setToken } from '@/utils/token';
// function addSmartlook(data){
//   smartlook('identify', data.id, {
//     "name": data.name,
//   });
// }
function syncAuthBox(to, next) {
  MessageBox.confirm(
    '您当前个号下无广告帐户, 请同步帐户或前往配置页面重新授权相应个号，否则无法进行后续操作！',
    '同步帐户',
    {
      confirmButtonText: '同步帐户',
      cancelButtonText: '取消',
      type: 'warning',
    },
  )
    .then(() => {
      syncBM(to, next);
    })
    .catch((res) => {
      Message({
        message: '已取消同步帐号！',
        type: 'info',
      });
    });
}

export function syncBM(to, next) {
  Vue.prototype.$showLoading();
  synBM({
    type: 'account',
  }).then((res) => {
    Vue.prototype.$hideLoading();
    if (res.code == 200) {
      Message.success(res.comment);
      getAdAccounts(to, next);
    }
  });
}

export function initActiveAdAccount(arr) {
  if (arr.length) {
    // 启用帐号中的第一个
    let eableAdAccounts = arr.filter((item) => item.accountStatus == 1);
    if (eableAdAccounts) {
      return {
        ...eableAdAccounts[0],
      };
    } else {
      // 禁用帐号中的第一个
      return {
        ...arr[0],
      };
    }
  }
}

export function sorceSelectedNum() {
  let wnum = window.selectedNum,
    lnum = localStorage.getItem('selectedNum'),
    snum = sessionStorage.getItem('selectedNum'),
    lists = store.getters['num/validNumLists'];
  let currNUm = router.currentRoute.query.third_user_id;

  // pathNum =
  let id = currNUm
    ? currAccount
    : wnum && lists.find((el) => el.id == wnum)
    ? wnum
    : snum && lists.find((el) => el.id == snum)
    ? snum
    : lnum && lists.find((el) => el.id == lnum)
    ? lnum
    : store.getters['num/defNum']?.id || lists[0].id;
  console.log(router);
  return id;
}

export function sorceActiveAccount(to) {
  let lists =
    store.state.num.adAccountsLists && store.state.num.adAccountsLists.length
      ? [...store.state.num.adAccountsLists]
      : [];
  let hasLocalAccount = localStorage.getItem('activeAdAccount');
  let hasSessionAccount = sessionStorage.getItem('activeAdAccount');
  let currAccount = router.currentRoute.query.account_id;
  let obj = to.query.obj; //广告资产页面跳转广告管理携带过来的账户
  // 1是携带过来的-2是window下的-3是localstorage下的-4是列表中启用第一个-5是列表中禁用第一个
  let activeAdAccount = '';
  if (lists.length) {
    if (currAccount) {
      activeAdAccount = lists.find((el) => el.id == currAccount);
    } else if (obj && lists.find((el) => el.id == obj.id)) {
      activeAdAccount = lists.find((el) => el.id == obj.id);
    } else if (window.activeAdAccount && lists.find((el) => el.id == window.activeAdAccount.id)) {
      activeAdAccount = window.activeAdAccount;
    } else if (
      hasSessionAccount &&
      Object.keys(JSON.parse(hasSessionAccount)).length &&
      lists.find((el) => el.id == JSON.parse(hasSessionAccount).id)
    ) {
      activeAdAccount = JSON.parse(hasSessionAccount);
    } else if (
      hasLocalAccount &&
      Object.keys(JSON.parse(hasLocalAccount)).length &&
      lists.find((el) => el.id == JSON.parse(hasLocalAccount).id)
    ) {
      activeAdAccount = JSON.parse(hasLocalAccount);
    } else {
      activeAdAccount = initActiveAdAccount([...lists]);
    }
  } else {
    activeAdAccount = {};
  }
  // activeAdAccount = lists.length
  //   ? currAccount
  //     ? lists.find((el) => el.id == currAccount)
  //     : obj && lists.find((el) => el.id == obj.id)
  //     ? lists.find((el) => el.id == obj.id)
  //     : window.activeAdAccount && lists.find((el) => el.id == window.activeAdAccount.id)
  //     ? window.activeAdAccount
  //     : hasSessionAccount &&
  //       Object.keys(JSON.parse(hasSessionAccount)).length &&
  //       lists.find((el) => el.id == JSON.parse(hasSessionAccount).id)
  //     ? JSON.parse(hasLocalAccount)
  //     : hasLocalAccount &&
  //       Object.keys(JSON.parse(hasLocalAccount)).length &&
  //       lists.find((el) => el.id == JSON.parse(hasLocalAccount).id)
  //     ? JSON.parse(hasLocalAccount)
  //     : initActiveAdAccount([...lists])
  //   : {};
  return activeAdAccount;
}

export function getAdAccounts(to, next) {
  Vue.prototype.$showLoading();

  let _params = {
    keyword: '',
    limit: 10000,
    page: 1,
    id: store.state.num.selectedNum, //个号id
  };
  return adaccouts(_params).then(async (res) => {
    Vue.prototype.$hideLoading();

    if (res.code == 200) {
      // 广告帐号的所有信息数据以便其它页面调用（包括更新时间、花费等）
      await store.dispatch('num/setAdAccountsAllDatas', {
        allDatas: res.data,
      });

      await store.dispatch('num/setAdAccountsLists', {
        adAccountsLists: res.data.list && res.data.list.length ? [...res.data.list] : [],
      });
      await store.dispatch('num/setActiveAdAccount', {
        activeAdAccount: Object.keys(sorceActiveAccount(to)).length ? sorceActiveAccount(to) : res.data.list[0],
      });

      // 无帐号时停留在广告资产页面
      console.log(store.state.num.adAccountsLists.length);
      if (store.state.num.adAccountsLists.length) {
        if (to.meta.hideNavbarAccount) {
          // 无头部导航
          await store.dispatch('app/hideNavbarAccount', {
            hideNavbarAccount: true,
          });
        } else {
          // 有头部导航
          await store.dispatch('app/hideNavbarAccount', {
            hideNavbarAccount: false,
          });
        }
        next();
      } else {
        // 有头部导航
        await store.dispatch('app/hideNavbarAccount', {
          hideNavbarAccount: false,
        });
        if (to.name === 'AdAssets') {
          next();
          syncAuthBox(to, next);
        } else {
          if (to.name == 'UserInfo') {
            next();
            Message({
              message: '您当前个号下无广告帐户, 请在该页面重新授权相应个号，否则无法进行后续操作！',
              type: 'info',
            });
          } else {
            next({
              name: 'AdAssets',
            });
            syncAuthBox(to, next);
          }
        }
      }
    }
  });
}
export function getLoginList(platform, to, next) {
  if (to.path.includes('autoRulesResultM')) {
    next();
    return;
  }
  if (store.getters['num/validNumLists'] && store.getters['num/validNumLists'].length) {
    next();
    return;
  }
  Vue.prototype.$showLoading();
  let params = {
    platform,
  };
  myLoginList(params).then(async (res) => {
    Vue.prototype.$hideLoading();
    if (res.comment == 'ok') {
      if (!res.data || res.data.length == 0) {
        // 无个号-授权页面
        next({
          name: 'UserInfo',
        });
        // window.location.href = `/api/thirdoauth/fb?_redirect_page=` + encodeURIComponent("/#/userInfo/index");
      } else {
        if (to.meta.hideSideBar) {
          await store.dispatch('app/setSideBar', false);
        }
        // 个号列表存入-vuex
        await store
          .dispatch('num/setNumLists', {
            numLists: [
              ...res.data.sort((a, b) => new Date(b.create_time).getTime() - new Date(a.create_time).getTime()),
            ],
          })
          .then(async () => {
            let n = store.getters['num/validNumLists'] && store.getters['num/validNumLists'].length;
            if (n) {
              await store.dispatch('num/setSelectedNum', {
                selectedNum: sorceSelectedNum(),
              });
              await getAdAccounts(to, next);
            } else {
              // 无有效个号
              if (to.name === 'UserInfo') {
                next();
              } else {
                next({
                  name: 'UserInfo',
                });
              }
              MessageBox.confirm('您当前无有效个号, 请重新授权相关个号，否则无法进行后续操作！', '重新授权', {
                confirmButtonText: '重新授权',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => {
                  window.location.href = `/api/thirdoauth/fb?_redirect_page=` + encodeURIComponent('/#/userInfo/index');
                })
                .catch(() => {
                  Message({
                    message: '已取消重新授权！',
                    type: 'info',
                  });
                  next(false);
                });
            }
          });
      }
    }
  });
}
// 钉钉登录
export function ddLogin(to, next) {
  dd.ready(() => {
    getDdAuthCode(
      (info) => {
        let code = info.code;
        dingAuthCode(code)
          .then(async (response) => {
            if (response.code == 0) {
              try {
                let resp = await profile();
                store.commit('user/SETUSERINFO', resp);
              } catch (error) {
                alert(JSON.stringify(error));
              }
              getLoginList('facebook', to, next);
            } else if (response.code == 11) {
              const list = response.data.map((v) => v.account);
              dd.device.notification.actionSheet({
                title: '请选择单点账号', //标题
                cancelButton: '取消', //取消按钮文本
                otherButtons: list,
                onSuccess: function (result) {
                  let buttonIndex = result.buttonIndex;
                  if (buttonIndex == -1) {
                    alert('已取消登录');
                    next(false);
                  } else {
                    let userId = response.data[buttonIndex].id;
                    getDdAuthCode(
                      (inf) => {
                        let cod = inf.code;
                        dingAuthCode(cod, userId)
                          .then(async (respons) => {
                            if (respons.code == 0) {
                              try {
                                let resp = await profile();
                                store.commit('user/SETUSERINFO', resp);
                              } catch (error) {
                                alert(JSON.stringify(error));
                              }
                              getLoginList('facebook', to, next);
                            } else {
                              alert('接口code报错：' + JSON.stringify(respons));
                              next(false);
                            }
                          })
                          .catch((err) => {
                            next(false);
                            alert('接口报错：' + JSON.stringify(err));
                          });
                      },
                      (err) => {
                        next(false);
                      },
                    );
                  }
                },
                onFail: function (err) {},
              });
            } else {
              alert('接口code报错：' + JSON.stringify(response));
              next(false);
            }
          })
          .catch((err) => {
            next(false);
            alert('接口报错：' + JSON.stringify(err));
          });
      },
      (err) => {
        next(false);
      },
    );
  });
}

function getDdAuthCode(success, fail) {
  dd.runtime.permission.requestAuthCode({
    corpId: 'ding795387c883ef25ff35c2f4657eb6378f',
    onSuccess: (info) => {
      success && success(info);
    },
    onFail: (err) => {
      console.log(err);
      fail && fail(err);
      alert('钉钉报错：' + JSON.stringify(err));
    },
  });
}

let LAND_PAGE = [
  {
    role: 13,
    path: '/asset/openAccount', //代理对接
    weight: 20,
  },
  {
    role: 95,
    path: '/asset/openAccount', //代理对接
    weight: 20,
  },
  {
    role: 3,
    path: '/asset/entityManage', //业务助理
    weight: 20,
  },
  {
    role: 7,
    path: 'ad/adManagement', //优化师
    weight: 40,
  },
  {
    role: 6,
    path: 'ad/adManagement', //优化师组长
    weight: 30,
  },
  {
    role: 4,
    path: '/asset/mainPageManage', //主页运营
    weight: 10,
  },
  {
    role: 94,
    path: '/asset/mainPageManage', //主页运营测试
    weight: 10,
  },
  {
    role: 9,
    path: '/asset/mainPageManage', //客服专员
    weight: 8,
  },
  {
    role: 10,
    path: '/asset/mainPageManage', //客服主管
    weight: 9,
  },
  {
    role: 32,
    path: 'ad/adManagement', //研发人员
    weight: 50,
  },
  {
    role: 90,
    path: '/asset/numManage', //个号管理
    weight: 7,
  },
  {
    role: 8,
    path: '/asset/submitDomain', //商城维护
    weight: 7,
  },
  {
    role: 96,
    path: '/asset/submitDomain', //商城维护测试
    weight: 7,
  },
  {
    role: 91,
    path: '/asset/openAccountTask', //开户人员测试
    weight: 7,
  },
  {
    role: 57,
    path: '/asset/openAccountTask', //开户人员正式
  },
  {
    role: 110,
    path: '/ad/adManagement', //吉客拓客
  },
];

function getWeightRole(roles) {
  let weightList = LAND_PAGE.filter((v) => roles.includes(v.role));
  weightList.sort((a, b) => {
    return b.weight - a.weight;
  });
  // console.log(weightList[0]);
  return weightList[0];
}

// 添加登陆的window事件(重新登陆的小窗口回调)
window.successLogin = async function () {
  let res = await profile();
  Cookies.set('userToken', {
    _user: res.data.id,
    _token: res.data.token,
  });
  let token = getToken();
  if (!token || token == 'null') {
    token = res.data.token;
  }
  res.data.token = token;
  setToken(token);
  localStorage.setItem('userInfo', JSON.stringify(res.data));
  store.commit('user/SETUSERINFO', res);
  // 关闭重复弹出登陆的msgBOx
  var el_message_box = document.querySelector('.el-message-box__wrapper');
  var v_modal = document.querySelector('.v-modal');
  if (el_message_box && v_modal) {
    el_message_box.parentNode.removeChild(el_message_box);
    v_modal.parentNode.removeChild(v_modal);
  }
};
router.beforeEach(async (to, from, next) => {
  // 如果地址栏里面有_token, 更新本地cookie
  if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'development') {
    localStorage.setItem('token_workFlow', to.query._token);
  }

  let flag = from.path == '/' && to.name == 'accountOverview';

  let isLogin = store.state.user.userInfo.data?.logined ?? false;
  if (!isLogin && !to.meta.needNotProfile) {
    //防止登录后路由切换时再次触发初次登录的相关动作
    let res = await profile();
    Cookies.set('userToken', {
      _user: res.data.id,
      _token: res.data.token,
    });
    let token = getToken();
    if (!token || token == 'null') {
      token = res.data.token;
    }
    setToken(token);

    res.data.token = token;
    localStorage.setItem('userInfo', JSON.stringify(res.data));
    addMark();
    // addSmartlook(res.data);
    store.commit('user/SETUSERINFO', res);
    if (res.code == 0) {
      // console.log(res);
      if (!res.data.logined) {
        if (dd.env.platform != 'notInDingTalk') {
          ddLogin(to, next);
        } else {
          if (to.meta.requireThirdLogin) {
            window.location.href = `/api/login/check?_redirect_page=${location.pathname}${location.hash}`;
          } else {
            next();
          }
        }
      } else {
        // to.query.account_id = from.query.account_id || null
        // to.query.third_user_id = from.query.third_user_id || null
        if (from.query.account_id) to.query.account_id = from.query.account_id;
        if (from.query.third_user_id) to.query.third_user_id = from.query.third_user_id;
        // ifAccountNone(to, next,flag)
        if (flag) {
          next(getWeightRole(res.data.roles).path);
        } else {
          next();
        }
      }
    } else {
      next(false);
    }
  } else {
    if (from.query.account_id) to.query.account_id = from.query.account_id;
    if (from.query.third_user_id) to.query.third_user_id = from.query.third_user_id;
    if (flag) {
      // console.log(store.state.user.userInfo);
      next(getWeightRole(store.state.user.userInfo.data.roles).path);
    } else {
      next();
    }
  }
});
// 有个号无账号
function ifAccountNone(to, next, flag) {
  if (to.meta?.requireThirdLogin && !store.state.num.adAccountsLists.length && to.name !== 'AdAssets') {
    getAdAccounts(to, next);
  } else {
    if (flag) {
      // console.log(store.state.user.userInfo);
      next(getWeightRole(store.state.user.userInfo.data.roles).path);
    } else {
      next();
    }
  }
  // return;
}
router.beforeResolve(async (to, from, next) => {
  let isLogin = store.state.user.userInfo.data?.logined ?? false;
  if (!isLogin && !to.meta.needNotProfile) {
    //防止登录后路由切换时再次触发初次登录的相关动作
    if (dd.env.platform != 'notInDingTalk') {
      ddLogin(to, next);
    } else if (to.meta.requireThirdLogin) {
      window.location.href = `/api/login/check?_redirect_page=${location.pathname}${location.hash}`;
    } else {
      next();
    }
  } else {
    if (to.meta?.roles?.length) {
      // 单账户控制权限
      if (to.meta.ids && !to.meta.ids.includes(store.state.user.userInfo.data.id)) {
        Message({
          message: '您没有访问该页面的权限，请重新输入访问地址',
          type: 'info',
        });
        next(false);
        return;
      }
      if (!to.meta.roles.some((v) => store.state.user.userInfo.data.roles.includes(v))) {
        Message({
          message: '您没有访问该页面的权限，请重新输入访问地址',
          type: 'info',
        });
        next(false);
        return;
      }
    }
    if (to.query.obj) {
      await store.dispatch('num/setActiveAdAccount', {
        activeAdAccount: sorceActiveAccount(to),
      });
    }
    if (to.meta.requireThirdLogin) {
      getLoginList('facebook', to, next);
    } else {
      next();
    }
  }
});
let routerIdList = [
  { link: '/ad/adManagement', id: 1237 },
  { link: '/ad/createAd', id: 1246 },
  { link: '/google/batchAdManage', id: 1243 },
  { link: 'https://gads3.giikin.com/fastDeliveryOnly', id: 1244 },
  { link: '/custom/customAudience', id: 1245 },
  { link: '/ad/autoRulesResult', id: 1241 },
  { link: '/ad/bmBind', id: 1242 },
  { link: '/ad/AutoRules', id: 1239 },
  { link: '/ad/fbBatchAdManage', id: 1238 },
  { link: '/ad/AdAssets', id: 1238 },
];
window.GKHelperMenuID = '';
router.afterEach((to, from) => {
  window.GKHelperUserID = store.state.user.userInfo.data ? store.state.user.userInfo.data.id : null;
  window.GKHelperMenuID = routerIdList.find((v) => v.link == to.path)?.id;
  store.dispatch('app/hideNavbarAccount', {
    hideNavbarAccount: to.meta.hideNavbarAccount,
  });
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.meta && to.meta.isMobile) {
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    meta.setAttribute('content', 'yes');
    meta.setAttribute('name', 'apple-mobile-web-app-capable');
    head[0].appendChild(meta);
    let meta2 = document.createElement('meta');
    meta2.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');
    meta2.setAttribute('name', 'viewport');
    head[0].appendChild(meta2);
  }
});
